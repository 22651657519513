import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes = [
  {
    path: '/new',
    name: 'new',
    component: () => import('../views/new/index.vue'),
    meta: {
      title: 'Homepage',
      hideBack: 1,
    },
  },
  {
    path: '/',
    name: 'login',
    component: () => import('../views/login/index.vue'),
    meta: {
      hideBack: 1,
    },
  },
  {
    path: '/project',
    name: 'project',
    component: () => import('../views/project/index.vue'),
    meta: {
      title: 'Homepage',
      hideBack: 1,
    },
  },
  {
    path: '/concerning',
    name: 'concerning',
    component: () => import('../views/concerning/index.vue'),
    meta: {
      title: 'Homepage',
      hideBack: 1,
    },
  },
  {
    path: '/indoor',
    name: 'indoor',
    component: () => import('../views/indoor/index.vue'),
    meta: {
      title: 'Homepage',
      hideBack: 1,
    },
  },
  {
    path: '/commercial',
    name: 'commercial',
    component: () => import('../views/commercial/index.vue'),
    meta: {
      title: 'commercial',
      hideBack: 1,
    },
  },
  {
    path: '/building',
    name: 'building',
    component: () => import('../views/building/index.vue'),
    meta: {
      title: 'Homepage',
      hideBack: 1,
    },
  },
  {
    path: '/furniture',
    name: 'furniture',
    component: () => import('../views/furniture/index.vue'),
    meta: {
      hideBack: 2,
    },
  },
  {
    path: '/figure',
    name: 'figure',
    component: () => import('../views/figure/index.vue'),
    meta: {
      title: 'Homepage',
      hideBack: 1,
    },
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('../views/detail/index.vue'),
    meta: {
      title: 'Homepage',
      hideBack: 1,
    },
  },
  {
    path: '/indoorDetail',
    name: 'indoorDetail',
    component: () => import('../views/detail/indoor.vue'),
    meta: {
      title: 'Homepage',
      hideBack: 1,
    },
  },
  {
    path: '/commercialDetail',
    name: 'commercialDetail',
    component: () => import('../views/detail/commercial.vue'),
    meta: {
      title: 'Homepage',
      hideBack: 1,
    },
  },
  {
    path: '/furnitureDetail',
    name: 'furnitureDetail',
    component: () => import('../views/detail/furniture.vue'),
    meta: {
      title: 'Homepage',
      hideBack: 1,
    },
  },
  {
    path: '/buildingDetail',
    name: 'buildingDetail',
    component: () => import('../views/detail/building.vue'),
    meta: {
      title: 'Homepage',
      hideBack: 1,
    },
  },
  {
    path: '/contact_us',
    name: 'contact_us',
    component: () => import('../views/contact_us/index.vue'),
    meta: {
      title: 'Homepage',
      hideBack: 1,
    },
  },
  {
    path: '/job',
    name: 'job',
    component: () => import('../views/job/index.vue'),
    meta: {
      title: 'Homepage',
      hideBack: 1,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router