<template>
  <div>
    <div class="header-wrap" >
      <img src="../../src/assets/pc_images/new/logo.png" @click="$router.push({ name: 'login' })" style="padding-top: 20px;width: 40px ;height: 40px">
      <div style="padding-top: 5px">
        <div class="new_main span-line" :class="indexPage==1 ? 'active':''" @click="nav('login',1)" >{{ $t("common.news") }}</div>
        <div class="new_main span-line" :class="indexPage==2 ? 'active':''" @click="nav('commercial',2)">{{ $t("common.works") }}</div>
        <div class="unfold" v-if="project">
          <div class="tips_main span-line"  :class="tips==24 ? 'active':''" @click="navTo('commercial',24)">{{ $t("common.Commercial") }}</div>
          <div class="tips_main span-line" :class="tips==21 ? 'active':''" @click="navTo('indoor',21)">{{ $t("common.interior") }}</div>
<!--          <div class="tips_main span-line" :class="tips==22 ? 'active':''" @click="navTo('building',22)">{{ $t("common.architecture") }}</div>-->
<!--          <div class="tips_main span-line" :class="tips==23 ? 'active':''" @click="navTo('furniture',23)">{{ $t("common.furniture") }}</div>-->
<!--          <div class="tips_main span-line"  :class="tips==24 ? 'active':''" @click="navTo('commercial',24)">{{ $t("common.Commercial") }}</div>-->
        </div>
        <div class="new_main span-line" :class="indexPage==6 ? 'active':''" @click="nav('building',6)" >{{ $t("common.architecture") }}</div>
        <div class="new_main span-line" :class="indexPage==7 ? 'active':''" @click="nav('new',7)" >{{ $t("common.furniture") }}</div>
        <div class="new_main span-line" :class="indexPage==3 ? 'active':''" @click="nav('concerning',3)">{{ $t("common.about") }}</div>
        <div class="unfold" v-if="concerning">
          <div class="news_main span-line"  :class="indexPages==31 ? 'active':''" @click="hide('concerning',31)">
            <span class="unfold-main">-</span>{{ $t("common.info") }}</div>
          <div class="news_main span-line"  :class="indexPages==32 ? 'active':''" @click="hide('figure',32)">
            <span class="unfold-main">-</span>{{ $t("common.people") }}</div>
        </div>
        <div class="new_main span-line" :class="indexPage==4 ? 'active':''" @click="nav('job',4)" >{{ $t("common.Jobs") }}</div>
        <div class="new_main span-line" :class="indexPage==5 ? 'active':''" @click="nav('contact_us',5)">{{ $t("common.Contact") }}</div>
      </div>
      <div class="english_main span-line"  @click="changeLanguage">{{ $t("common.English") }}</div>
    </div>
<!--    <mt-popup-->
<!--        v-model="langSelectVisible"-->
<!--        position="bottom"-->
<!--        pop-transition="popup-fade"-->
<!--        closeOnClickModal="true"-->
<!--        class="popupLayer"-->
<!--    >-->
<!--      <div class="langPopup">-->
<!--        <div class="langClickButton">-->
<!--          <div class="left-btn c-btn" @click="handleChangeLangClick">-->
<!--            {{ $t("common.confirm") }}-->
<!--          </div>-->
<!--          <div class="right-btn c-btn" @click="handleChangeLangClick">-->
<!--            {{ $t("common.cancel") }}-->
<!--          </div>-->
<!--        </div>-->
<!--        <mt-picker :slots="langList" @change="onLanguageChange"></mt-picker>-->
<!--      </div>-->
<!--    </mt-popup>-->
  </div>
</template>
<script>
import { languageList, setLanguage, i18n } from "@/utils/i18n";

export default {
  data() {
    return {
      concerning:false,
      indexPage:1,
      indexPages:31,
      tips:24,
      project:false,
      title: "common.defaultTitle",
      show: true,
      showBack: false,
      langSelectVisible: false,
      langMap: { ...languageList },
      selectLang:
          Object.keys(languageList)[
              Object.values(languageList).indexOf(i18n.locale)
              ],
      langList: [
        {
          flex: 1,
          values: [...Object.keys(languageList)],
          className: "language",
          textAlign: "center",
        },
      ],
    };
  },
  watch: {
    // $route() {
    //   let _r = this.$router.options.routes.find((r) => {
    //     return r.path == this.$route.path;
    //   });
    //   if (_r) {
    //     this.show = _r.ext.showHeader;
    //     this.title = _r.ext.title;
    //     this.showBack = !_r.ext.isTabbar;
    //   }
    // },
  },
  methods: {
    nav(title,index){
      this.$router.push({ name: title })
      this.indexPage=index
      if( index== 3){
        this.concerning=!this.concerning
      }else if(index== 2){
        this.project=!this.project
      }else {
        this.concerning=false
        this.project=false
      }
    },
    hide(title,index){
      this.$router.push({ name: title })
      this.indexPages=index
      if( index== 31){
        this.concerning=true
      } else if(index== 32){
        this.concerning=true
      }
      else {
        this.concerning=false
      }
    },
    navTo(title,index){
      this.$router.push({ name: title })
      this.tips=index
      // if( index== 31){
      //   this.concerning=true
      // } else if(index== 32){
      //   this.concerning=true
      // }
      // else {
      //   this.concerning=false
      // }
    },

    changeLanguage () {
      if (this.$i18n.locale == 'cn') {
        localStorage.setItem('lang', 'en')
        this.$i18n.locale = 'en'
      } else {
        this.$i18n.locale = 'cn'
        localStorage.setItem('lang', 'cn')
      }
      location.reload()
    },
    // onLanguageChange($__, values) {
    //   this.selectLang = this.langMap[values[0]];
    // },
    handleChangeLangClick() {
      setLanguage(this.selectLang);
      this.langSelectVisible = !this.langSelectVisible;
    },
    goBack() {
      window.history.back();
    },
  },
};
</script>
<style scoped>
/*.new_main{*/
/*  text-decoration: underline !important;*/
/*  color: #000 !important;*/
/*  font-size: 12px !important;*/
/*  background: none !important;*/
/*  line-height: 14px !important;*/
/*}*/

.header-wrap{

}


.new_main{
  /*text-decoration: underline !important;*/
  color: #000;
  font-size: 12px;
  background: none;
  line-height: 14px;
  font-family: 'Times', 'Simsun';
  margin-top: 5px;
}
.news_main{
  /*text-decoration: underline !important;*/
  color: #000;
  font-size: 12px;
  background: none;
  line-height: 14px;
  margin-top: 5px;
  font-family: 'Times', 'Simsun';
}
.tips_main{
  /*text-decoration: underline !important;*/
  color: #000;
  font-size: 12px;
  background: none;
  line-height: 14px;
  margin-top: 5px;
  font-family: 'Times', 'Simsun';
}
.tips_main.active{
  text-decoration:underline;
}
.tips_main:before{
  content: "- ";
  color: #e7662b;
  font-size: 16px;
}
.english_main{
  color: #000;
  font-size: 12px;
  background: none;
  line-height: 14px;
  margin-top: 30px;
}
.unfold{
  padding-left: 2px;
}
.span-line:hover{
  text-decoration:underline;
}
.unfold-main{
  color: #e7662b;
  font-size: 12px;
  margin-bottom: 1px;
  height: 16px;
  line-height: 12px;
  padding-right: 1px;
}
.new_main.active{
  text-decoration:underline;
}
.news_main.active{
  text-decoration:underline;
}
.header-wrap{

  /*overflow:hidden;*/
  text-overflow:ellipsis;
  /*white-space:nowrap;*/
  position: fixed;
  left: 0px;
  z-index: 999;
  /*width: 30px;*/
  /*width: 100%;*/
  /*!*margin-left: 30px;*!*/
  text-align: left;
  padding: 0px 15px 0px 10px;
}
/*.header-wrap{*/
/*  width: 100%;*/
/*  !*margin-left: 30px;*!*/
/*  text-align: left;*/
/*  padding: 0px 15% 0px 15%;*/
/*}*/
.header-other {
  left: 0px;
  margin-left: 0px;
}
.header {
  width: 148px;
}
.h1 {
  width: 140px;
  float: left;
  margin-left: 30px;
  margin-top: 20px;
}
.h2 {
  width: 140px;
  float: left;
  margin-left: 30px;
  margin-top: 15px;
}
.menu-main-menu-container {
  width: 140px;
  float: left;
}
.menu {
  width: 108px;
}


.top-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}
.popupLayer {
  width: 100%;
}
.langPopup {
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  height: 260px;
}
.langClickButton {
  display: flex;
  height: 40px;
}
.langClickButton .c-btn {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  color: #7c7c7c;
}
.langClickButton .c-btn.left-btn {
  position: absolute;
  width: 50px;
  height: 15px;
  left: 17px;
  top: 17px;
}
.langClickButton .c-btn.right-btn {
  position: absolute;
  width: 43px;
  height: 15px;
  right: 17px;
  top: 17px;
}

::v-deep .mint-header-title {
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
}
::v-deep .top-header .mintui.mintui-back {
  font-size: 25px;
}

/*Global Color*/
::v-deep .mint-header {
  background-color: #FFFFFF;
}

::v-deep .mint-popup.popupLayer.mint-popup-bottom {
  border-radius: 20px 20px 0px 0px;
}

::v-deep .picker-item.picker-selected {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  border: none;
}

::v-deep .picker-center-highlight {
  background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0) 100%
  ),
  #ffffff;
  z-index: -1;
}
::v-deep .picker-center-highlight:before,
::v-deep .picker-center-highlight:after {
  display: none;
}
</style>
