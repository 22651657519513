import Vue from 'vue'
import VueI18n from 'vue-i18n'

//TODO:Init global I18n
Vue.use(VueI18n)

const defaultLang = "cn";
const i18n = new VueI18n({
    locale: (() => {
        if (localStorage.getItem('lang')) {
            return localStorage.getItem('lang')
        }
        return defaultLang;
    })(),
    messages: {
        'en': require('../assets/language/en.json'),
        'cn': require('../assets/language/cn.json')
    }
})
const languageList = {
    "English": 'en',
    '中文': 'zh',
}

function setLanguage(lang) {
    localStorage.setItem('lang', lang)
    i18n.locale = lang
}


export { i18n, languageList, setLanguage }

