import request from '@/utils/request'
//登录
export function login(data) {
  return request({
    url: '/api.user/login',
    method: 'post',
    data
  })
}
//获取登录账号信息
export function getInfo(token) {
  return request({
    url: '/api.user/info',
    method: 'get',
    params: { token }
  })
}
//更新账号信息
export function updateUser(data) {
  return request({
    url: '/api.user/updateUser',
    method: 'post',
    data
  })
}

//更新账号密码
export function updatePassword(data) {
  return request({
    url: '/api.user/updatePassword',
    method: 'post',
    data
  })
}

export function logout() {
  return request({
    url: '/api.user/logout',
    method: 'post'
  })
}
